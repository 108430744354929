<template>
  <div class="col-span-full text-center text-text-tertiary dark:text-text-tertiary-dark">
    <div
      v-for="day in schedule"
      :key="day.name"
      class="my-3 flex flex-col rounded-lg border-2 p-3 dark:border-border-primary-dark dark:bg-bg-primary-dark">
      <div class="relative flex items-center">
        <div class="flex w-[calc(100%-2rem)] space-x-1">
          <div :data-e2e="`pro-enable-schedule-${day.id}`">
            <CrossgridSwitch v-model="day.enabled" :label="day.name" :disabled="isLoading" />
          </div>
          <div
            v-if="day.enabled && !day.expanded"
            class="overflow-hidden text-ellipsis whitespace-nowrap">
            · {{ displayTimesCollapsed(day) }}
          </div>
        </div>
        <div
          v-if="day.enabled"
          class="absolute right-0 h-5 w-5 cursor-pointer"
          @click="toggleTimesExpanded(day)">
          <div v-if="!day.expanded">
            <ChevronDownIcon />
          </div>
          <div v-else>
            <ChevronUpIcon />
          </div>
        </div>
      </div>
      <div v-if="day.enabled && day.expanded">
        <div
          v-for="(businessHour, index) in day.businessHours"
          :key="index"
          class="flex items-center pt-3">
          <CrossgridListbox
            v-model="businessHour.fromHour"
            :data-e2e="`pro-from-hour-${day.id}-${index}`"
            :options="generatedTimes"
            :disabled="isLoading" />
          <div class="px-2">to</div>
          <CrossgridListbox
            v-model="businessHour.toHour"
            :data-e2e="`pro-to-hour-${day.id}-${index}`"
            :options="generatedTimes"
            :disabled="isLoading" />
          <div>
            <div v-if="index > 0" class="ml-1 flex items-center">
              <button
                class="h-5 w-5 text-text-error-primary dark:text-text-error-primary-dark"
                @click="removeTimes(day, index)">
                <TrashIcon />
              </button>
            </div>
            <div v-if="day.businessHours.length > 1 && index === 0" class="ml-1 w-5"></div>
          </div>
        </div>
        <button
          class="mt-3 flex w-full items-center justify-center rounded-full border-2 py-1 dark:border-border-primary-dark"
          :data-e2e="`pro-add-timeslot-${day.id}`"
          @click="addTimes(day)">
          <div class="h-5 w-5"><PlusIcon /></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, reactive, Ref, ref, toRaw, watch} from 'vue';
import {ChevronDownIcon, ChevronUpIcon, PlusIcon, TrashIcon} from '@heroicons/vue/24/solid';
import equal from 'fast-deep-equal';

import {WEEKDAYS} from '@/constants/common';
import {ProBusinessHours, ProSchedule} from '@/types/pro-profile';
import {MinimalUser} from '@/types/user';
import {
  addedProAvailability,
  convertTo12HourTime,
  convertTo24HourTime,
  deletedProAvailability,
  generatedTimes,
  isLoading,
  isSaved,
  proAvailability,
  setProAvailability,
  updatedProAvailability,
} from '@/utils/pro-profile';
import {getSession} from '@/utils/shared';

import CrossgridListbox from '@/components/atoms/CrossgridListbox.vue';
import CrossgridSwitch from '@/components/atoms/CrossgridSwitch.vue';

const daysOfWeek = WEEKDAYS;
const defaultTimes = {fromHour: '9:00 AM', toHour: '5:00 PM'};

const proAvailabilityChanges = ref<ProBusinessHours[]>([]);

const schedule: ProSchedule[] = reactive(
  daysOfWeek.map((name, index) => {
    return {
      id: index + 1,
      name,
      enabled: false,
      expanded: true,
      businessHours: [{...defaultTimes}],
    };
  })
);

const addTimes = (day: ProSchedule) => {
  day.businessHours.push({...defaultTimes});
};

const removeTimes = (day: ProSchedule, index: number) => {
  if (day.businessHours.length > 1) {
    day.businessHours.splice(index, 1);
  }
};

const toggleTimesExpanded = (day: ProSchedule) => {
  day.expanded = !day.expanded;
};

const displayTimesCollapsed = (day: ProSchedule) => {
  return day.businessHours
    .map(businessHour => `${businessHour.fromHour} - ${businessHour.toHour}`)
    .join(' · ');
};

const userSession: Ref<MinimalUser | null> = ref(null);
const proListing: Ref<string> = ref('');

const displaySchedule = (availability: string | null) => {
  // convert from DB format to UI format
  const parsedAvailability: ProBusinessHours[] = availability && JSON.parse(availability);
  if (availability) {
    schedule.forEach(day => {
      const availability = parsedAvailability.filter((a: ProBusinessHours) => a.weekday === day.id);
      if (availability.length > 0) {
        day.enabled = true;
        day.businessHours = availability.map((a: ProBusinessHours) => ({
          fromHour: convertTo12HourTime(a.from_hour),
          toHour: convertTo12HourTime(a.to_hour),
          slug: a.slug,
        }));
      }
    });
  }
};

const setScheduleChanges = (availability: ProSchedule[]) => {
  // convert from UI format to DB format
  const enabledSchedules = availability.filter(s => s.enabled);
  const formattedSchedule: ProBusinessHours[] = enabledSchedules.flatMap(s =>
    s.businessHours.map(businessHour => ({
      from_hour: convertTo24HourTime(businessHour.fromHour),
      to_hour: convertTo24HourTime(businessHour.toHour),
      weekday: s.id,
      pro_listing: proListing.value,
      slug: businessHour.slug,
    }))
  );

  proAvailabilityChanges.value = formattedSchedule;
  formattedSchedule &&
    localStorage.setItem('pro_availability_changes', JSON.stringify(formattedSchedule));

  addedProAvailability.value = formattedSchedule.filter(
    (day: ProBusinessHours) =>
      !proAvailability.value.some((d: ProBusinessHours) => d.slug === day.slug)
  );
  updatedProAvailability.value = formattedSchedule.filter((day: ProBusinessHours) =>
    proAvailability.value.some(
      (d: ProBusinessHours) =>
        d.slug === day.slug &&
        (d.from_hour !== day.from_hour || d.to_hour !== day.to_hour || d.weekday !== day.weekday)
    )
  );
  deletedProAvailability.value = toRaw(proAvailability.value).filter(
    (day: ProBusinessHours) => !formattedSchedule.some((d: ProBusinessHours) => d.slug === day.slug)
  );
};

watch(schedule, () => {
  setScheduleChanges(schedule);
  isSaved.value = equal(proAvailability.value, proAvailabilityChanges.value);
});

watch(proAvailability, () => {
  const proAvailabilityChangesFromStorage = localStorage.getItem('pro_availability_changes');
  if (proAvailabilityChangesFromStorage && proAvailabilityChangesFromStorage.length > 0) {
    displaySchedule(proAvailabilityChangesFromStorage);
  } else {
    displaySchedule(JSON.stringify(proAvailability.value));
  }
});

onMounted(() => {
  getSession()?.then(session => {
    userSession.value = session;
    proListing.value = session?.pro_listing as string;
    setProAvailability(proListing.value);
  });
});
</script>
